import React from 'react';

import { Helmet } from 'react-helmet';
import ModuleTiles from './ModuleTiles';
import { Data } from '../Data/data';

const Functional = () => {
    return (
        <>
            <Helmet>
                <title>Rise Infotech | Functional Modules </title>
                <meta name="description" content="Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing." />
                <meta name="keywords" content="SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling" />
                <link rel="canonical" href="/technical" />
            </Helmet>
            <ModuleTiles modules={Data.sapFunctionalModules} />
        </>
    )
}

export default Functional