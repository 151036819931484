export const Data = {
  contactInfo: {
    email: "info@riseinfotech.in",
    phone: "+91 9110 455 125",
    displayPhone: "+91 - 9110 455 125"

  },
  socialMediaList: [
    {
      id: "instagram",
      url: "https://www.instagram.com/rise_infotech/",
      image: "assets/images/instagram.png",
    },
    {
      id: "facebook",
      url: "https://www.facebook.com/Rise-Infotech-100291029634133/",
      image: "assets/images/facebook.png",
    },
    {
      id: "twitter",
      url: "https://twitter.com/RiseInfotech",
      image: "assets/images/twitter.png",
    },
    {
      id: "linkedin",
      url: "https://www.linkedin.com/company/rise-infotech/",
      image: "assets/images/linkedin.png",
    },
    {
      id: "youtube",
      url: "https://www.youtube.com/@riseinfotech2023",
      image: "assets/images/youtube.png",
    },
  ],
  featuresHighlights: [
    {
      id: 1,
      image: "assets/images/features/icon1.png",
      title: "Online Courses From Experts",
    },
    {
      id: 2,
      image: "assets/images/features/icon2.png",
      title: "Real Time Projects",
    },
    {
      id: 3,
      image: "assets/images/features/icon1.png",
      title: "100% Placement Assistance",
    },
  ],

  whyChooseOurServiceList: [
    {
      id: 1,
      image: "assets/images/hero/p1.png",
      title: "Expert Instructors",
    },
    {
      id: 2,
      image: "assets/images/hero/p3.png",
      title: "Flexible Learning Options",
    },
    {
      id: 3,
      image: "assets/images/hero/p5.png",
      title: "Career Advancement",
    },
    {
      id: 4,
      image: "assets/images/hero/p7.png",
      title: "Proven Track Record",
    },
    {
      id: 6,
      image: "assets/images/hero/p2.png",
      title: "Comprehensive Curriculum",
    },
    {
      id: 8,
      image: "assets/images/hero/p6.png",
      title: "Personalized Attention",
    },
    {
      id: 9,
      image: "assets/images/hero/p8.png",
      title: "Hands-On Learning",
    },
    {
      id: 10,
      image: "assets/images/hero/p10.png",
      title: "Certification Support",
    },
  ],
};

export const FooterData = {
  AboutUs: `
    At RISE INFOTECH, our curriculum is meticulously crafted in collaboration with industry experts to ensure that our students gain practical, real-world knowledge that sets them apart in the competitive job market in the field of SAP IT SERVICES AND IT CONSULTING.
    `,
  FollowUsLabel: "Support us",
};

export const TestimonialStudentData = [
  {
    id: 1,
    text: "My experience with Rise Infotech was fantastic. Study materials, notes provided were very useful. Trainers are co-operative and supportive. They are always available to solve our issues. Revision classes and mock sessions boosted my confidence level and I cleared my interviews. Thank you.",
    name: "Suprith",
    company: "Robert Bosch",
    role: "SAP-MM Consultant",
    companyLogo: "assets/images/company-logo/bosch.svg",
  },
  {
    id: 12,
    text: "I am thoroughly impressed with the SAP-MM training at Rise Infotech. The curriculum is tailored to industry needs and the practical assignments were incredibly beneficial. This course has definitely given me a competitive edge in the job market. Thank you, Rise Infotech.",
    name: "Basava",
    company: "AIRBUS",
    role: "SAP-MM Consultant",
    companyLogo: "assets/images/company-logo/airbus.svg",
  },
  {
    id: 3,
    text: "Hi Rise Infotech Team, I am profoundly grateful for the opportunity to grow and learn alongside such a talented and supportive team. Your unwavering encouragement has empowered me to reach new heights and pursue ambitious goals with determination and resilience. PPT sessions, extra classes, revisions and mock were useful. Thank you, Rise Infotech.",
    name: "Raghavendra",
    company: "Infosys",
    role: "SAP-MM Consultant",
    companyLogo: "assets/images/company-logo/infosys.svg",
  },
  {
    id: 5,
    text: "It's Very clear that everyone will get an ample amount of knowledge, for the time spent. With the Explanation and notes provided anybody can work on any projects without any support or guidance right from the start. To face the interview and get job only thing required is attend daily mock interview sessions and learn from the feedback given to you once you clear Rise infotech mock it is more than sufficient to clear all available company interviews. Overall, I am much happier to be Rise infotechie.",
    name: "Venkat",
    company: "CGI",
    role: "SAP-MM Consultant",
    companyLogo: "assets/images/company-logo/cgi.svg",
  },
  {
    id: 6,
    text: "The course was well-structured, with clear learning objectives and well-organized course materials. The instructor (Kumar) is a knowledgeable person, patient, and provided valuable insights that were practical and applicable to real-world scenarios. I would like to thank for the exceptional assistance and guidance you provided in helping me prepare for my SAP MM job interview. Thank you.",
    name: "Sachin",
    company: "Motive Minds",
    role: "SAP-MM Consultant",
    companyLogo: "assets/images/company-logo/motive-minds.png",
  },
  {
    id: 7,
    text: "In my experience, Rise Infotech team is very supportive and friendly and the placement assistance has been very smooth throughout. I would always be very grateful for giving me bright future in the path of SAP MM. Thank you, Rise Infotech.",
    name: "Naveen",
    company: "NTT Data",
    role: "SAP-MM Consultant",
    companyLogo: "assets/images/company-logo/ntt-data.svg",
  },
  {
    id: 11,
    text: "Rise Infotech provides a very supportive learning environment. The instructors are patient and always available to answer questions. The course has given me a solid foundation in SAP-MM and I'm excited to apply what I've learned. Thank you, Rise Infotech.",
    name: "Chandru",
    company: "Capgemini",
    role: "SAP-MM Consultant",
    companyLogo: "assets/images/company-logo/capgemini.svg",
  },
  {
    id: 13,
    text: "The quality of training at Rise Infotech is exceptional. The SAP-MM course was well-structured and the instructors were clearly experts in their field. This training has greatly enhanced my professional skills and career prospects. Thank you.",
    name: "Vinay",
    company: "Accenture",
    role: "SAP-MM Consultant",
    companyLogo: "assets/images/company-logo/accenture.svg",
  },
  {
    id: 4,
    text: "I joined SAP MM course at Rise Infotech last year. It was a game changer for my career. The syllabus was up to date with the latest industry trends and project class was really helpful to gain practical knowledge. I landed a job as SAP MM consultant within two months of completing the course! Thank you, Rise Infotech.",
    name: "Shashidhar",
    company: "Infosys",
    role: "SAP-MM Consultant",
    companyLogo: "assets/images/company-logo/infosys.svg",
  },
  {
    id: "13-1",
    text: "Rise Infotech has changed my life completely. I joined the course with them with an intention of getting good job. But they taught me everything not only SAP-MM, they taught how to improve communication skills, personality development, email writing skills, excel upgradation etc. Now I am perfect in SAP-MM as well as improved my other skills. Thank you Rise Infotech.",
    name: "Chaitra",
    company: "Mindsprint",
    role: "",
    companyLogo: "assets/images/company-logo/mindsprint.svg",
  },
  {
    id: 9,
    text: "I joined SAP MM course at Rise Infotech last year. It was a game changer for my career. The syllabus was up to date with the latest industry trends and project class was really helpful to gain practical knowledge. I landed a job as a SAP MM consultant within two months of completing the course! Thank you, Rise Infotech.",
    name: "Bhargav",
    company: "PWC",
    role: "SAP-MM Consultant",
    companyLogo: "assets/images/company-logo/pwc.svg",
  },
  {
    id: 10,
    text: "The SAP MM course was comprehensive and engaging. The study materials and real-world case studies helped me solidify my understanding of the material. I highly recommend Rise Infotech to anyone looking to advance their career in SAP-MM. Thank you, Rise Infotech.",
    name: "Dev",
    company: "Infosys",
    role: "SAP-MM Consultant",
    companyLogo: "assets/images/company-logo/infosys.svg",
  },
  {
    id: "13-2",
    text: "Hello Rise Infotech, thank you for being the reason for my career enhancement. The co-operation and guidance I have received from Kumar and other team members is unmatched. I will definitely recommend Rise Infotech to everyone who wants to learn SAP.",
    name: "Vinay",
    company: "Mindsprint",
    role: "",
    companyLogo: "assets/images/company-logo/mindsprint.svg",
  },
  {
    id: 8,
    text: "Rise Infotech is the reason why I could succeed in my career. The training provided was advanced which made me clear interview and get 100% hike in my dream company. Thank you, Rise Infotech.",
    name: "Divya",
    company: "Mercedes Benz",
    role: "SAP-MM Consultant",
    companyLogo: "assets/images/company-logo/mercedes-benz.png",
  },
];
export const StudentList = [
  {
    id: 31,
    message: "",
    name: "Manoj",
    company: "PWC",
    role: "",
    companyLogo: "assets/images/company-logo/pwc.svg",
  },  
  {
    id: 15,
    message: "",
    name: "Anadraj",
    company: "Wipro",
    role: "",
    companyLogo: "assets/images/company-logo/wipro.png",
  },
  {
    id: 21,
    message: "",
    name: "Chaitra",
    company: "Genpact",
    role: "",
    companyLogo: "assets/images/company-logo/genpact.svg",
  },
  {
    id: 32,
    message: "",
    name: "Naveen",
    company: "NTT data",
    role: "",
    companyLogo: "assets/images/company-logo/ntt-data.svg",
  },

  {
    id: 18,
    message: "",
    name: "Basava",
    company: "AIRBUS",
    role: "",
    companyLogo: "assets/images/company-logo/airbus.svg",
  },
  {
    id: 14,
    message: "",
    name: "Amaresh",
    company: "Infosys",
    role: "",
    companyLogo: "assets/images/company-logo/infosys.svg",
  },
  {
    id: 47,
    message: "",
    name: "Vipul",
    company: "Mercedes Benz",
    role: "",
    companyLogo: "assets/images/company-logo/mercedes-benz.png",
  },
  {
    id: 20,
    message: "",
    name: "Bhargav",
    company: "PWC",
    role: "",
    companyLogo: "assets/images/company-logo/pwc.svg",
  },

  {
    id: 22,
    message: "",
    name: "Chandru",
    company: "Capgemini",
    role: "",
    companyLogo: "assets/images/company-logo/capgemini.svg",
  },
  {
    id: 23,
    message: "",
    name: "Chetan",
    company: "TCS",
    role: "",
    companyLogo: "assets/images/company-logo/tcs.svg",
  },
  {
    id: "23-2",
    message: "",
    name: "Chetan",
    company: "PWC",
    role: "",
    companyLogo: "assets/images/company-logo/pwc.svg",
  },
  {
    id: "15-2",
    message: "",
    name: "Anadraj",
    company: "Deloitte",
    role: "",
    companyLogo: "assets/images/company-logo/deloitte.svg",
  },
  {
    id: 16,
    message: "",
    name: "Anand M",
    company: "Deloitte",
    role: "",
    companyLogo: "assets/images/company-logo/deloitte.svg",
  },
  {
    id: 17,
    message: "",
    name: "Arul",
    company: "Eviden",
    role: "",
    companyLogo: "assets/images/company-logo/eviden.png",
  },
  {
    id: 24,
    message: "",
    name: "Chetan K",
    company: "Deloitte",
    role: "",
    companyLogo: "assets/images/company-logo/deloitte.svg",
  },
  {
    id: 27,
    message: "",
    name: "Ganesh",
    company: "Deloitte",
    role: "",
    companyLogo: "assets/images/company-logo/deloitte.svg",
  },
  {
    id: 26,
    message: "",
    name: "Divya",
    company: "Mercedes Benz",
    role: "",
    companyLogo: "assets/images/company-logo/mercedes-benz.png",
  },
  {
    id: 42,
    message: "",
    name: "Saraswati",
    company: "Motherson",
    role: "",
    companyLogo: "assets/images/company-logo/motherson.svg",
  },
  {
    id: 28,
    message: "",
    name: "Ganesh S",
    company: "Tietoevry",
    role: "",
    companyLogo: "assets/images/company-logo/tietoevry.svg",
  },
  {
    id: 29,
    message: "",
    name: "Jagan",
    company: "Accenture",
    role: "",
    companyLogo: "assets/images/company-logo/accenture.svg",
  },
  {
    id: 19,
    message: "",
    name: "Basavaraj",
    company: "Accenture",
    role: "",
    companyLogo: "assets/images/company-logo/accenture.svg",
  },
  {
    id: 30,
    message: "",
    name: "Manjunath",
    company: "ITC infotech",
    role: "",
    companyLogo: "assets/images/company-logo/itc-infotech.png",
  },
  {
    id: 25,
    message: "",
    name: "Dev",
    company: "Infosys",
    role: "",
    companyLogo: "assets/images/company-logo/infosys.svg",
  },
  {
    id: 33,
    message: "",
    name: "Sushant",
    company: "Eviden",
    role: "",
    companyLogo: "assets/images/company-logo/eviden.png",
  },
  {
    id: 34,
    message: "",
    name: "Raghavendra",
    company: "Infosys",
    role: "",
    companyLogo: "assets/images/company-logo/infosys.svg",
  },
  {
    id: 35,
    message: "",
    name: "Rishi",
    company: "Mercedes Benz",
    role: "",
    companyLogo: "assets/images/company-logo/mercedes-benz.png",
  },
  {
    id: 36,
    message: "",
    name: "Sabari",
    company: "Wipro",
    role: "",
    companyLogo: "assets/images/company-logo/wipro.png",
  },

  {
    id: 38,
    message: "",
    name: "Sanjeev",
    company: "Wipro",
    role: "",
    companyLogo: "assets/images/company-logo/wipro.png",
  },
  {
    id: 39,
    message: "",
    name: "Shashidhar",
    company: "Infosys",
    role: "",
    companyLogo: "assets/images/company-logo/infosys.svg",
  },
  {
    id: 40,
    message: "",
    name: "Shashi",
    company: "L&T",
    role: "",
    companyLogo: "assets/images/company-logo/lt.svg",
  },
  {
    id: 41,
    message: "",
    name: "Shivaraj",
    company: "TCS",
    role: "",
    companyLogo: "assets/images/company-logo/festo-india.svg",
  },
  {
    id: 43,
    message: "",
    name: "Sreekanth",
    company: "L&T",
    role: "",
    companyLogo: "assets/images/company-logo/lt.svg",
  },
  {
    id: 44,
    message: "",
    name: "Suprith",
    company: "Robert Bosch",
    role: "",
    companyLogo: "assets/images/company-logo/bosch.svg",
  },
  {
    id: 48,
    message: "",
    name: "Vinay",
    company: "Accenture",
    role: "",
    companyLogo: "assets/images/company-logo/accenture.svg",
  },
  {
    id: 50,
    message: "",
    name: "Vinod",
    company: "Deloitte",
    role: "",
    companyLogo: "assets/images/company-logo/deloitte.svg",
  },
  {
    id: 37,
    message: "",
    name: "Sachin",
    company: "Motiveminds",
    role: "",
    companyLogo: "assets/images/company-logo/motive-minds.png",
  },
  {
    id: 45,
    message: "",
    name: "Tharun",
    company: "Exide",
    role: "",
    companyLogo: "assets/images/company-logo/exide.svg",
  },
  {
    id: 51,
    message: "",
    name: "Vivek",
    company: "Bosch",
    role: "",
    companyLogo: "assets/images/company-logo/bosch.svg",
  },
  {
    id: 46,
    message: "",
    name: "Venkat",
    company: "CGI",
    role: "",
    companyLogo: "assets/images/company-logo/cgi.svg",
  },
  {
    id: 2,
    text: "Joining SAP MM course at Rise Infotech was the one of the best decisions of my life. It helped me shape my career in a much better way. The notes and study material provided were beneficial. The course structure allowed me to build a strong portfolio and clear interviews. Thank you, Rise Infotech.",
    name: "Vipul",
    company: "Mercedes Benz",
    role: "SAP-MM Consultant",
    companyLogo: "assets/images/company-logo/mercedes-benz.png",
  },
  {
    id: 49,
    message: "",
    name: "Vinayak",
    company: "L&T",
    role: "",
    companyLogo: "assets/images/company-logo/lt.svg",
  },
];

export const TestimonialPageData = {
  title: "Success Stories",
  TestimonialStudentData,
  studentData: StudentList,
  loadMore: "See All Testimonials",
};
