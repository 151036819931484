import React from "react";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Data } from "../Data/data";

export default function Contact() {
  return (
    <div>
      <Helmet>
        <title>Rise Infotech | Contact Us </title>
        <meta name="description" content="Rise Infotech Contact For Information" />
        <meta name="keywords" content="sap, salesforce" />
        <meta rel="canonical" href="/contact" />
      </Helmet>
      {/* <!-- Contact Info Start --> */}
      <section className="contact-info-area rel z-1 rpt-90 rpb-100">
        <div className="container">
          <div className="row ">
            <div className="col-lg-8">
              <div className="contact-info-wrap rmb-75   ">
                {/* wow fadeInUp delay-0-2s */}
                <div className="section-title mb-25">
                  <span className="sub-title-two">Need Any Helps ?</span>
                  <h2>Contact Us For More Information</h2>
                </div>
                <p></p>
                <div className="row no-gap mt-50">

                  <div className="col-md-6 col-sm-6">
                    <div className="contact-info-box">
                      <i className="far fa-envelope"></i>
                      <h4>Email Us</h4>
                      <span>

                        <Link to="https://demo.webtend.net/cdn-cgi/l/email-protection#8be2e5ede4e8e4eae8e3a5e5eeff">
                          {Data.contactInfo.email}
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="contact-info-box">
                      <i className="fas fa-phone-volume"></i>
                      <h4>Phone</h4>
                      <span>

                        <Link to={`callto:${Data.contactInfo.phone}`}>{Data.contactInfo.displayPhone}</Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact-page-image ">
                <img
                  src="assets/images/contact/contact-page1.png"
                  alt="Contact Page"
                />
              </div>
            </div>
          </div>
        </div>        
      </section>      
    </div>
  );
}
