import React from 'react';

import { Helmet } from 'react-helmet';
import ModuleTiles from './ModuleTiles';
import { Data } from '../Data/data';

const Technical = () => {
    return (
        <>
            <Helmet>
                <title>Rise Infotech | Technical Modules </title>
                <meta name="description" content="Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - " />
                <meta name="keywords" content="SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling" />
                <link rel="canonical" href="/technical" />
            </Helmet>

            <ModuleTiles modules={Data.sapTechnicalModules} />
        </>
    )
}

export default Technical